<template>
    <BT-Blade-Item
        bladeName="courier-payment-terms"
        navigation="courier-payment-terms"
        title="Courier Payment Terms"
        :defaultBladeWidth="500">
        <template slot-scope="{ data, item }">
            
            <BT-Field-String
                v-model="item.paymentTermsName"
                :isEditing="data.isEditing || data.isNew"
                label="Name" />

            <BT-Field-Number
                v-model.number="item.daysToPay"
                label="Days To Pay"
                :isEditing="data.isEditing || data.isNew" />

            <BT-Field-Checkbox
                v-model="item.endOfMonth"
                label="End Of Month"
                :isEditing="data.isEditing || data.isNew" />

        </template>
    </BT-Blade-Item>
</template>

<script>
export default {
    name: 'Courier-Payment-Term',
}
</script>